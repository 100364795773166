import React from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  dialog_content: {
    marginBottom: 15,
  },
  cancel_button: {
    color: theme.palette.error.main,
  },
  confirm_button: {
    color: theme.palette.success.main,
  },
}))

export const RemoveDialog = ({
  setValue,
  disabled = false,
  isOpen,
  handleToggle,
}) => {
  const handleRemove = () => {
    setValue(null)
    handleToggle()
  }

  const classes = useStyles()

  return (
    <Dialog open={isOpen} onClose={handleToggle}>
      <DialogTitle>
        Usuń
      </DialogTitle>
      <DialogContent
        className={classes.dialog_content}
      >
        <DialogContentText>
          Czy napewno chcesz usunąć plik?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleToggle}
          className={classes.cancel_button}
          disabled={disabled}
          autoFocus
        >
          Anuluj
        </Button>
        <Button
          onClick={handleRemove}
          className={classes.confirm_button}
          disabled={disabled}
        >
          Potwierdź
        </Button>
      </DialogActions>
    </Dialog>
  )
}

RemoveDialog.propTypes = {
  type: PropTypes.oneOf(['file', 'image']),
  setValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  handleToggle: PropTypes.func.isRequired,
}
