import { authConstants, commonConstants } from '_constants'

const initialState = {
  isSidebarOpen: true,
}

export const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case commonConstants.SIDEBAR_TOGGLE:
      return {
        ...state,
        isSidebarOpen: !state.isSidebarOpen,
      }
    case commonConstants.SET_CURRENT_RESOURCE:
      return {
        ...state,
        currentResource: action.payload.resource,
      }
    case commonConstants.SET_CURRENT_PARENT_RESOURCE:
      return {
        ...state,
        currentParentResource: action.payload.resource,
      }
    case commonConstants.RESET_CURRENT_RESOURCES:
      return {
        ...state,
        currentResource: null,
        currentParentResource: null,
      }
    case authConstants.REFRESH_FAILURE:
    case authConstants.LOGOUT:
      return initialState
    default:
      return state
  }
}
