import photoSchema from './photo'

const schema = {
  endpoint: '/api/pages',
  resource: {
    definition: 'Page-page.read',
    properties: {
      title: {
        width: 400
      },
      subtitle: {
        width: 500
      },
      text: {
        type: 'tinymce',
      },
      photo: {
        type: 'image',
        description: 'Zdjęcie',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
      },
      idName: {
        hint: 'Wypełnia tylko programista (służy to identyfikacji strony)'
      },
      slug: {
        type: 'slug',
        routes: '/:slug',
        hint: 'Automatycznie generowany z tytułu',
        width: 300
      },
      stat: {}
    },
  },
}

export default schema
