const translations = {
  pl: {
    Error: 'Błąd',
    TypeError: 'Błąd',
    'An error occurred': 'Błąd',
    'Invalid credentials.': 'Niepoprawne dane',
    'Syntax error': 'Błąd składni. Skontaktuj się z administratorem',
    Forbidden: 'Brak dostępu do tego zasobu',
    Unauthorized: 'Dostęp zablokowany',
    'Internal Server Error': 'Wystąpił błąd. Skontaktuj się z administratorem',
    'TypeError: Failed to fetch': 'Błąd połączenia z serwerem. Skontaktuj się z administratorem',
  },
}

export const translate = (
  message,
  values = [],
  locale = 'pl'
) =>
  Object.keys(translations[locale]).includes(message)
    ? values.length
      ? translations[locale][message]
          .split('%')
          .map((value, index) => value + (values[index] ? values[index] : ''))
          .join('')
      : translations[locale][message]
    : message
