import React, { useState } from 'react'
import { Button, TextField } from '@material-ui/core'
import { validators } from '_helpers/validators'
import { translate } from '_helpers/translate'
import { useStyles } from '.'

export const ResetPasswordLink = () => {
  const [state, setState] = useState({
    email: null,
    errorMessage: null,
    isSubmitted: false,
    isInvalid: true,
    isFetching: false,
    isCompleted: false,
  })

  const handleChange = e => {
    const email = getValue(e.target.value)

    const isInvalid =
      !validators.required(email).result || !validators.email(email).result
    setState(state => ({
      ...state,
      email,
      errorMessage: isInvalid
        ? 'Niepoprawny adres email'
        : null,
      isInvalid,
    }))
  }

  const url = `${process.env.REACT_APP_API_ENTRYPOINT}/api/reset-password/link`

  const handleSubmit = e => {
    e.preventDefault()

    setState(state => ({
      ...state,
      isSubmitted: true,
    }))

    if (state.isInvalid) {
      return
    }

    setState(state => ({
      ...state,
      isFetching: true,
    }))

    fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    })
      .then(response =>
        response.text().then(text =>
          text
            ? JSON.parse(text)
            : {
                success: false,
                errorMessage: 'Błąd',
              }
        )
      )
      .then(data => {
        if (data.success !== true) {
          setState(state => ({
            ...state,
            errorMessage: translate(data.message),
            isFetching: false,
          }))

          return
        }

        setState(state => ({
          ...state,
          isFetching: false,
          isCompleted: true,
        }))
      })
  }

  const { email, errorMessage, isSubmitted, isFetching, isCompleted } = state

  const classes = useStyles()

  return (
    <>
      <div className={classes.dialog_title}>
        Resetowanie hasła
      </div>
      {isCompleted ? (
        <div className={classes.success_message}>
          Wysłaliśmy link na podany adres email
        </div>
      ) : (
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <div className={classes.form_body}>
            <TextField
              className={classes.input}
              error={isSubmitted && !!errorMessage}
              helperText={isSubmitted && !!errorMessage ? errorMessage : null}
              value={email || ''}
              onChange={handleChange}
              required={true}
              label="Twój adres email"
              margin="normal"
              fullWidth={true}
              autoFocus={true}
            />
          </div>
          <Button
            disabled={isFetching}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {isFetching ? (
              <img
                className={classes.loader}
                src="/img/react-logo.svg"
                alt="loading"
              />
            ) : 'Wyślij mi link'}
          </Button>
        </form>
      )}
    </>
  )
}

const getValue = value => (value === '' ? null : value.trim())
