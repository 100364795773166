import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import { validate } from '_helpers/validate'

const TEXTAREA_ROWS = 6
const TEXTAREA_MAX_ROWS = 10

export const StringType = ({
  name,
  type = 'string',
  label,
  hint = null,
  initialValue,
  value,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
  fullWidth = false,
  width = null
}) => {
  const handleChange = e => {
    const value =
      type === 'integer'
        ? parseInt(e.target.value)
        : type === 'number'
        ? parseFloat(e.target.value)
        : e.target.value === ''
        ? null
        : e.target.value
    setValue(name, value)
    validateField(value)
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  useEffect(() => {
    validateField(initialValue)

  }, [validateField, initialValue])

  const additional =
    type === 'integer'
      ? { inputProps: { step: 1 } }
      : type === 'textarea'
      ? {
          rows: TEXTAREA_ROWS,
          rowsMax: TEXTAREA_MAX_ROWS,
          multiline: true,
          FormHelperTextProps: { style: { marginLeft: 0 } },
        }
      : {}

  return (
    <>
      <TextField
        name={name}
        type={
          type === 'integer'
            ? 'number'
            : ['string', 'textarea'].includes(type)
            ? 'text'
            : type
        }
        label={
          (label.text || label) +
          (validators && validators.includes('required') ? ' *' : '')
        }
        value={value || ''}
        disabled={disabled}
        onChange={handleChange}
        error={renderError && !!error}
        helperText={renderError && error ? error : hint}
        InputLabelProps={
          label.color && {
            style: {
              color: label.color,
            },
          }
        }
        fullWidth={fullWidth}
        style={width ? { width } : {}}
        variant="outlined"
        {...additional}
      />
    </>
  )
}

StringType.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  width: PropTypes.number
}
