import React, { useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Form } from 'pages'
import { applyProperties } from '_helpers/applyProperties'
import { MultipleUploadDialog } from './components/MultipleUploadDialog'
import { EmbeddedCollection } from 'components/embedded'
import schema from '_schema/event'
import routes from './routes'
import photoSchema from '_schema/photo'

const useStyles = makeStyles({
  embedded: {
    marginTop: 20
  },
  multiple_upload: {
    marginTop: 20,
    marginLeft: 15
  }
})

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const iri = `${schema.endpoint}/${match.params.id}`

  const multipleUploadProperties = useMemo(
    () =>
      applyProperties(
        {
          title: {
            ...schema.subresources.photo.properties.title,
            validate: null,
            width: 500
          },
          stat: {
            ...schema.subresources.photo.properties.stat,
            defaultValue: true
          }
        },
        definitions[schema.subresources.photo.definition].properties,
        'missing'
      ),
    [definitions]
  )

  const [embeddedCollectionKey, setEmbeddedCollectionKey] = useState(Math.random())

  const handleMassUploadSuccess = () => {
    setEmbeddedCollectionKey(Math.random())
  }

  const classes = useStyles()

  return (
    <Form
      definitionSchema={definitions[schema.resource.definition]}
      customResourceSchema={schema.resource}
      iri={iri}
      method="PUT"
      url={iri}
      collectionPath={routes().index.path}
      storeCollectionId={schema.endpoint}
      width={300}
    >
      <div className={classes.embedded}>
        <EmbeddedCollection
          endpoint={schema.subresources.photo.endpoint}
          pid={match.params.id}
          parentIri={iri}
          properties={schema.subresources.photo.properties}
          definitionSchema={definitions[schema.subresources.photo.definition]}
          headerTitle="Zdjęcia"
          panelTitle="Zdjęcie"
          key={embeddedCollectionKey}
        />
        <div className={classes.multiple_upload}>
          <MultipleUploadDialog
            title="Wgraj wiele zdjęć"
            parentIri={iri}
            fileEndpoint={photoSchema.endpoint.multiple}
            itemEndpoint={schema.subresources.photo.endpoint}
            itemProperties={multipleUploadProperties}
            handleMassUploadSuccess={handleMassUploadSuccess}
          />
        </div>
      </div>
    </Form>
  )
}
