import { put, call } from 'redux-saga/effects'
import { profileConstants } from '_constants'
import { profileService } from '_services'
import { handleAuthError } from '_helpers/handleAuthError'
import { prop } from '_helpers/prop'
import schema from '_schema/profile'

function* fetch() {
  yield put({ type: profileConstants.REQUEST })

  const { response, error } = yield call(profileService.get)

  if (response) {
    yield put({
      type: profileConstants.SUCCESS,
      payload: {
        ...[
          'uuid',
          ...Object.keys(schema.profile.properties),
        ].reduce(
          (obj, key) => Object.assign(obj, { [key.split('.')[0]]: prop(response, key) }), {}
        ),
      },
    })

    return
  }

  yield call(handleAuthError.saga, error)

  return
}

const update = values => ({
  type: profileConstants.UPDATE,
  payload: {
    ...Object.keys(schema.profile.properties).reduce(
      (obj, key) => Object.assign(obj, { [key.split('.')[0]]: prop(values, key) }), {}
    ),
  },
})

export const profileActions = {
  fetch,
  update,
}
