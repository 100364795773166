import { createMuiTheme } from '@material-ui/core/styles'
import { plPL } from '@material-ui/core/locale'

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#3a6e43',
    },
    secondary: {
      main: '#5A6169',
    },
    disabled: '#bbb',
    error: {
      main: '#b52b2b',
    },
    success: {
      main: '#34914d',
    },
    info: {
      main: '#57a3c9'
    },
    warning: {
      main: '#ebad41'
    },
  }
}, plPL)
