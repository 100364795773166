import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { MassUpload } from 'components/MassUpload'
import { Form } from 'components/form'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'

const useStyles = makeStyles(theme => ({
  second_step_container: {
    textAlign: 'left',
  },
  chosen_files: {
    fontSize: '1.2em',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginBottom: 10,
  },
  apply_to_all: {
    fontSize: '1.1em',
    marginBottom: 10,
  },
}))

export const MultipleUpload = ({
  parentIri,
  fileEndpoint,
  itemEndpoint,
  itemProperties,
  fileProperty = 'file',
  fileType = 'file',
  validators = ['maxSize', 'maxFiles'],
  accept = null,
  hint = null,
  disabled = false,
  onUploadSuccess = null,
  onResourceSubmit = null,
  onResourceCancel = null,
}) => {
  const [files, setFiles] = useState([])

  const [state, setState] = useState({
    current: null,
    isFetching: false,
  })

  const onMassUploadSuccess = response => {
    onUploadSuccess && onUploadSuccess(response)

    setFiles(response['hydra:member'])
  }

  const onMassResourceSubmit = resource => {
    setState(state => ({
      ...state,
      isFetching: true,
    }))

    uploadItem({ ...resource, parent: parentIri }, state.current || 0)
  }

  const uploadItem = (resource, index) => {
    setState({
      ...state,
      isFetching: true,
      current: index,
    })

    const file = files[index]

    fetchDataHandleAuthError(
      itemEndpoint,
      'POST',
      {
        body: JSON.stringify({
          ...resource,
          [fileProperty]: file,
        }),
      },
      () => {
        if (index + 1 < files.length) {
          uploadItem(resource, index + 1)
        } else {
          setState(state => ({
            ...state,
            isFetching: false,
          }))

          onResourceSubmit && onResourceSubmit(resource)

          notification('success', 'Rekordy utworzone')
        }
      },
      error => {
        setState(state => ({
          ...state,
          isFetching: false,
        }))

        notification(
          'error',
          error.response.violations.length
            ? 'Formularz niepoprawny'
            : error.response.detail
        )
      },
      {}
    )
  }

  const onMassResourceCancel = e => {
    onResourceCancel && onResourceCancel(e)
  }

  const classes = useStyles()

  return !files.length ? (
    <MassUpload
      type={fileType}
      hint={hint}
      endpoint={fileEndpoint}
      disabled={disabled}
      validators={validators}
      accept={accept}
      onSuccess={onMassUploadSuccess}
    />
  ) : (
    <div className={classes.second_step_container}>
      <div className={classes.chosen_files}>
        {state.current === null
          ? 'Dodajesz :value plików'.replace(':value', files.length)
          : `Dodaję ${state.current + 1} z ${files.length}`}
      </div>
      <div className={classes.apply_to_all}>
        Nadaj wszystkim dodawanym
      </div>
      <Form
        url={itemEndpoint}
        method="POST"
        disabled={disabled || state.isFetching}
        properties={itemProperties}
        handleSubmit={onMassResourceSubmit}
        handleCancel={onMassResourceCancel}
        showSubmitAndStayButton={false}
        width={500}
      />
    </div>
  )
}

MultipleUpload.propTypes = {
  parentIri: PropTypes.string.isRequired,
  fileEndpoint: PropTypes.string.isRequired,
  itemEndpoint: PropTypes.string.isRequired,
  itemProperties: PropTypes.object.isRequired,
  fileProperty: PropTypes.string,
  fileType: PropTypes.oneOf(['file', 'image']),
  validators: PropTypes.arrayOf(PropTypes.string),
  accept: PropTypes.string,
  hint: PropTypes.string,
  disabled: PropTypes.bool,
  onUploadSuccess: PropTypes.func,
  onResourceSubmit: PropTypes.func,
  onResourceCancel: PropTypes.func,
}
