import { getCurrentRoute } from './getCurrentRoute'

export const generateBreadcrumbs = (path, routes) => generatePath(path, routes)

const generatePath = (path, routes, init = true) => {
  const matchedRoute = getCurrentRoute(path, routes)?.route

  if (!matchedRoute) {
    return []
  }

  const subtitle =
    init && matchedRoute.subtitle
      ? [{ path: null, title: matchedRoute.subtitle }]
      : []

  return [
    ...[{
      path: matchedRoute.indexPath || path,
      title: matchedRoute.title,
      ...Object.assign({}, matchedRoute.parent && { self: matchedRoute.parent }),
    }],
    ...subtitle,
  ]
}
