export const initState = ({
  properties,
  resource,
  defaultData,
  url,
  method,
  onlyPassedProperties,
}) => ({
  values: Object.assign(
    onlyPassedProperties || method === 'POST'
      ? { ...defaultData }
      : { ...defaultData, ...resource },
    ...Object.keys(properties).map(name => ({
        [name]:
          resource?.[name] !== undefined
            ? resource[name]
            : properties[name].defaultValue !== undefined
            ? properties[name].defaultValue
            : null,
      }))
  ),
  errors: Object.assign(
    {},
    ...Object.keys(properties).map(name => ({ [name]: false }))
  ),
  renderError: Object.assign(
    {},
    ...Object.keys(properties).map(name => ({ [name]: false }))
  ),
  isInvalid: false,
  isProcessing: false,
  isSubmitted: false,
  url:
    resource &&
    resource?.uuid &&
    url.includes(':uuid')
      ? url.replace(
          ':uuid',
          resource.uuid
        )
      : url,
})
