import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Dialog, Button, Card, TextField } from '@material-ui/core'
import { ResetPasswordLink } from './ResetPasswordLink'
import { Logo } from 'components/Logo'
import { Copyright } from 'components/Copyright'
import { authActions } from '_actions'
import { validate } from 'email-validator'
import { useStyles } from '.'

export const Login = () => {
  const [state, setState] = useState({
    email: '',
    password: '',
    submitted: false,
  })
  const dispatch = useDispatch()

  const handleChange = e => {
    const { name, value } = e.target
    setState(state => ({
      ...state,
      [name]: value,
    }))
  }

  const handleSubmit = e => {
    e.preventDefault()
    setState(state => ({ ...state, submitted: true }))
    const { email, password } = state

    if (validate(email) && password) {
      dispatch(authActions.login(email, password))
    }
  }

  const isLogging = useSelector(state => state.auth.isLogging)
  const { email, password, submitted } = state

  const [isResetLinkDialogOpen, setResetLinkDialogOpen] = useState(false)

  const handleResetLinkDialogToggle = () => {
    setResetLinkDialogOpen(state => !state)
  }

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <div className={classes.logo}>
          <Logo />
        </div>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <div className={classes.form_body}>
            <TextField
              className={classes.input}
              error={submitted && !validate(email)}
              helperText={
                submitted && !validate(email)
                  ? 'Niepoprawny email'
                  : null
              }
              value={email}
              onChange={handleChange}
              required={true}
              id="email"
              name="email"
              label="Adres email"
              margin="normal"
              fullWidth={true}
              autoComplete="email"
              autoFocus={true}
            />
            <TextField
              className={classes.input}
              error={submitted && !password}
              helperText={
                submitted && !password
                  ? 'Pole wymagane'
                  : null
              }
              value={password}
              onChange={handleChange}
              required={true}
              id="password"
              name="password"
              type="password"
              label="Hasło"
              margin="normal"
              fullWidth={true}
              autoComplete="current-password"
            />
          </div>
          <Button
            disabled={isLogging}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {isLogging ? (
              <img
                className={classes.loader}
                src="/img/react-logo.svg"
                alt="loading"
              />
            ) : 'Zaloguj'}
          </Button>
        </form>
      </Card>
      <Button
        onClick={handleResetLinkDialogToggle}
        variant="contained"
        color="secondary"
        className={classes.lower_button}
      >
        Zapomniałem hasła
      </Button>
      <Dialog onClose={handleResetLinkDialogToggle} open={isResetLinkDialogOpen}>
        <ResetPasswordLink />
      </Dialog>
      <Copyright />
    </div>
  )
}
