import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { prop } from '_helpers/prop'

const useStyles = makeStyles(theme => ({
  delete: {
    color: theme.palette.error.main,
  },
  update: {
    color: theme.palette.info.main,
  },
  create: {
    color: theme.palette.success.main,
  },
}))

export const MethodCell = ({ resource, accessor }) => {
  const method = prop(resource, accessor)

  const classes = useStyles()

  return (
    <div className={getClassName(method.toUpperCase(), classes)}>
      {getTitle(method.toUpperCase())}
    </div>
  )
}

const getClassName = (method, classes) => {
  switch(method) {
    case 'GET':
      return classes.view
    case 'PATCH':
    case 'PUT':
      return classes.update
    case 'POST':
      return classes.create
    case 'DELETE':
    default:
      return classes.delete
  }
}

const getTitle = method => {
  switch(method) {
    case 'GET':
      return 'Wyświetlenie'
    case 'PATCH':
    case 'PUT':
      return 'Edycja'
    case 'POST':
      return 'Utworzenie'
    case 'DELETE':
    default:
      return 'Usunięcie'
  }
}

MethodCell.propTypes = {
  resource: PropTypes.object.isRequired,
  accessor: PropTypes.string,
}
