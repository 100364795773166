import React from 'react'
import { ToastContainer } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { Router, Switch } from 'react-router-dom'
import { PrivateRoute } from 'components/route/PrivateRoute'
import { AnonymousRoute } from 'components/route/AnonymousRoute'
import { Login, ResetPasswordLink, ResetPasswordChange, Page } from 'pages'
import { hot } from 'react-hot-loader'
import { history } from '_helpers/history'
import { generateRoutes } from '_helpers/generateRoutes'
import { authActions } from '_actions'
import { useTokenRefresh } from '_helpers/useTokenRefresh'
import 'react-toastify/dist/ReactToastify.css'

const useStyles = makeStyles({
  app: {
    backgroundColor: '#f5f5f5',
  },
})

const App = () => {
  const classes = useStyles()

  const { data } = useSelector(state => state.auth)
  const dispatch = useDispatch()

  useTokenRefresh(
    data?.token,
    dispatch,
    authActions.logout,
    authActions.refresh
  )

  return (
    <>
      <Router history={history}>
        <div className={classes.app}>
          <Switch>{generateRoutes(routes())}</Switch>
        </div>
      </Router>
      <ToastContainer />
    </>
  )
}

const routes = () =>
  [
    {
      type: AnonymousRoute,
      component: Login,
      path: '/zaloguj',
      exact: true,
    },
    {
      type: AnonymousRoute,
      component: ResetPasswordLink,
      path: '/reset-hasla',
      exact: true,
    },
    {
      type: AnonymousRoute,
      component: ResetPasswordChange,
      path: '/reset-hasla/:hash/potwierdz',
      exact: true,
    },
    {
      type: PrivateRoute,
      component: Page,
      path: '/',
    },
  ]

export default process.env.NODE_ENV === 'development' ? hot(module)(App) : App
