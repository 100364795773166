import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1.5em',
  },
  title: {
    fontSize: 22,
  },
  buttons: {
    '&> *': {
      marginRight: 10,
    },
    '&> *:last-child': {
      marginRight: 0,
    },
  },
})

export const CollectionHeader = ({
  children,
  buttonTitle,
  buttonPath,
  additionalButtons = null,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        {children}
      </div>
      <div className={classes.buttons}>
        <Button
          component={Link}
          to={buttonPath}
          variant="contained"
          color="primary"
          size="medium"
        >
          {buttonTitle}
        </Button>
        {additionalButtons}
      </div>
    </div>
  )
}

CollectionHeader.propTypes = {
  children: PropTypes.node.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  buttonPath: PropTypes.string.isRequired,
  additionalButtons: PropTypes.node,
}
