import photoSchema from './photo'
import fileSchema from './file'

const schema = {
  endpoint: '/api/news',
  resource: {
    definition: 'News-news.read',
    properties: {
      title: {
        width: 600,
        validate: ['required']
      },
      subtitle: {
        width: 600
      },
      text: {
        type: 'tinymce'
      },
      btnTitle: {
        width: 400
      },
      photo: {
        type: 'image',
        endpoint: photoSchema.endpoint.single,
        validate: ['maxSize'],
      },
      file: {
        type: 'file',
        endpoint: fileSchema.endpoint.single,
        validate: ['maxSize'],
      },
      stat: {}
    },
  },
}

export default schema
