import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@material-ui/core'
import { MenuItem } from './MenuItem'
import { SubMenu } from './SubMenu'

export const Menu = ({ items = [], isSidebarOpen, ...rest }) => (
  <div {...rest}>
    {items.map((item, i) =>
      item.items ? (
        <SubMenu
          title={item.title}
          icon={item.icon}
          items={item.items}
          isSidebarOpen={isSidebarOpen}
          key={`sub-menu-${i}`}
        />
      ) : (
        <div key={`menu-item-${i}`}>
          {isSidebarOpen ? (
            <MenuItem item={item} />
          ) : (
            <Tooltip title={item.title} placement="right">
              <div>
                <MenuItem item={item} />
              </div>
            </Tooltip>
          )}
        </div>
      )
    )}
  </div>
)

const itemShape = PropTypes.shape({
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  icon: PropTypes.elementType.isRequired,
})

Menu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      itemShape,
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        icon: PropTypes.elementType.isRequired,
        items: PropTypes.arrayOf(itemShape).isRequired,
      }),
    ])
  ),
  isSidebarOpen: PropTypes.bool.isRequired,
}
