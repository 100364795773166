import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { matchPath } from 'react-router'
import { Chip } from '@material-ui/core'
import { getSchemaByRef } from '_helpers/getSchemaByRef'
import { removeQueryString } from '_helpers/removeQueryString'
import { prop } from '_helpers/prop'

export const ModuleCell = ({ resource, accessor }) => {
  const schema = useSelector(state => state.schema)

  const processedPath = useMemo(
    () => removeQueryString(prop(resource, accessor)),
    [resource, accessor]
  )

  const processedPaths = useMemo(
    () =>
      Object.keys(schema.paths).map(path =>
        path.replace('{', ':').replace('}', '')
      ),
    [schema.paths]
  )

  const matchedPath = useMemo(
    () =>
      getMatchedPath(processedPath, processedPaths, Object.keys(schema.paths)),
    [processedPath, processedPaths, schema.paths]
  )

  const description = useMemo(
    () =>
      getSchemaByRef(
        schema,
        prop(
          schema,
          `paths.${matchedPath}.get.responses.200.schema.${
            !['PUT', 'PATCH', 'DELETE'].includes(resource.requestMethod)
              ? 'items.'
              : ''
          }$ref`
        )
      )?.description,
    [schema, matchedPath, resource.requestMethod]
  )

  return description ? (
    <Chip label={description} color="primary" />
  ) : null
}

ModuleCell.propTypes = {
  resource: PropTypes.shape({
    requestMethod: PropTypes.string.isRequired,
  }).isRequired,
  accessor: PropTypes.string,
}

const getMatchedPath = (processedPath, processedPaths, originalPaths) => {
  for (let i = 0; i < processedPaths.length; i++) {
    if (
      matchPath({
        path: processedPaths[i],
        exact: true,
      }, processedPath)
    ) {
      return originalPaths[i]
    }
  }

  return null
}
