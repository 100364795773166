import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, TextField } from '@material-ui/core'
import { Logo } from 'components/Logo'
import { Copyright } from 'components/Copyright'
import { validators } from '_helpers/validators'
import { useStyles } from '.'

const PASSWORD_ERROR = 'Hasło musi składać się z min. 8 znaków i zawierać: małą literę, dużą literę, cyfrę i znak specjalny)'

export const ResetPasswordChange = ({ match }) => {
  const [state, setState] = useState({
    password: null,
    passwordRepeat: null,
    passwordErrorMessage: PASSWORD_ERROR,
    passwordRepeatErrorMessage: null,
    errorMessage: null,
    isSubmitted: false,
    isInvalid: true,
    isFetching: false,
    isCompleted: false,
  })

  const handleChange = {
    password: e => {
      const password = getValue(e.target.value)
      setState(state => ({
        ...state,
        password,
      }))
      validate(password, state.passwordRepeat)
    },
    passwordRepeat: e => {
      const passwordRepeat = getValue(e.target.value)
      setState(state => ({
        ...state,
        passwordRepeat,
      }))
      validate(state.password, passwordRepeat)
    },
  }

  const validate = (password, passwordRepeat) => {
    const isPasswordValid =
      validators.required(password).result &&
      validators.password(password).result

    setState(state => ({
      ...state,
      passwordErrorMessage: !isPasswordValid
        ? PASSWORD_ERROR
        : null,
    }))

    const isPasswordRepeatValid = password === passwordRepeat

    setState(state => ({
      ...state,
      passwordRepeatErrorMessage: !isPasswordRepeatValid
        ? 'Hasła nie zgadzają się'
        : null,
      isInvalid: !isPasswordValid || !isPasswordRepeatValid,
    }))
  }

  const url = `${process.env.REACT_APP_API_ENTRYPOINT}/api/reset-password/${match.params.hash}/confirm`

  const handleSubmit = e => {
    e.preventDefault()

    setState(state => ({
      ...state,
      isSubmitted: true,
    }))

    if (state.isInvalid) {
      return
    }

    setState(state => ({
      ...state,
      isFetching: true,
    }))

    fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ password }),
    })
      .then(response =>
        response.text().then(text =>
          text
            ? JSON.parse(text)
            : {
                success: false,
                errorMessage: 'Błąd',
              }
        )
      )
      .then(data => {
        if (data.success !== true) {
          setState(state => ({
            ...state,
            errorMessage: data.message,
            isFetching: false,
          }))

          return
        }

        setState(state => ({
          ...state,
          isFetching: false,
          isCompleted: true,
        }))
      })
  }

  const {
    password,
    passwordRepeat,
    passwordErrorMessage,
    passwordRepeatErrorMessage,
    errorMessage,
    isSubmitted,
    isFetching,
    isCompleted,
  } = state

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <div className={classes.logo}>
          <Logo />
        </div>
        <div className={classes.title}>
          Nowe hasło
        </div>
        {isCompleted ? (
          <div className={classes.success_message}>
            Hasło zostało poprawnie zmienione
          </div>
        ) : (
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            {errorMessage && (
              <div className={classes.password_error}>{errorMessage}</div>
            )}
            <div className={classes.form_body}>
              <TextField
                className={classes.input}
                error={isSubmitted && !!passwordErrorMessage}
                helperText={
                  isSubmitted && passwordErrorMessage
                    ? passwordErrorMessage
                    : null
                }
                value={password || ''}
                onChange={handleChange.password}
                required={true}
                label="Wpisz nowe hasło"
                type="password"
                margin="normal"
                fullWidth={true}
              />
              <TextField
                className={classes.input}
                error={isSubmitted && !!passwordRepeatErrorMessage}
                helperText={
                  isSubmitted && passwordRepeatErrorMessage
                    ? passwordRepeatErrorMessage
                    : null
                }
                value={passwordRepeat || ''}
                onChange={handleChange.passwordRepeat}
                required={true}
                label="Powtórz nowe hasło"
                type="password"
                margin="normal"
                fullWidth={true}
              />
            </div>
            <Button
              disabled={isFetching}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {isFetching ? (
                <img
                  className={classes.loader}
                  src="/img/react-logo.svg"
                  alt="loading"
                />
              ) : 'Zmień hasło'}
            </Button>
          </form>
        )}
      </Card>
      <Button
        component={Link}
        to="/zaloguj"
        variant="contained"
        color="primary"
        className={classes.lower_button}
      >
        Przejdź do logowania
      </Button>
      <Copyright />
    </div>
  )
}

const getValue = value => (value === '' ? null : value)
