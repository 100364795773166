const required = value => {
  const result = !!value
  return { result, message: !result && 'Pole wymagane' }
}

const email = value => {
  const result = value
    ? /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)
    : true

  return { result, message: !result && 'Niepoprawny email' }
}

const password = value => {
  const result = value
    ? /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
        value
      )
    : true

  return { result, message: !result && 'Hasło musi składać się z min. 8 znaków i zawierać: małą literę, dużą literę, cyfrę i znak specjalny)' }
}

const length = (value, length) => {
  const result = value ? value.toString().length >= length : true

  return {
    result,
    message: !result && 'To pole musi się składać z min. :value znaków'.replace(':value', length)
  }
}

const minValue = (value, min) => {
  const result = value ? value >= parseInt(min) : true

  return {
    result,
    message: !result && 'Minimalna wartość to :value'.replace(':value', min)
  }
}

const maxValue = (value, max) => {
  const result = value ? value <= parseInt(max) : true

  return {
    result,
    message: !result && 'Maksymalna wartość to :value'.replace(':value', max)
  }
}

const maxSize = (value, max = process.env.REACT_APP_MAX_UPLOAD_SIZE) => {
  if (Array.isArray(value)) {
    return maxSizeMultiple(value)
  }

  const result = value ? (value.size || value) <= parseInt(max) : true

  return {
    result,
    message:
      !result &&
      'Maksymalny rozmiar pliku to :value'.replace(':value', `${max / (1024 * 1024)} MB`),
  }
}

const maxSizeMultiple = (
  values,
  max = process.env.REACT_APP_MAX_UPLOAD_SIZE_MULTIPLE
) => {
  let completeSize = 0

  for (let i = 0; i < values.length; i++) {
    completeSize += values[i].size
  }

  const result = values.length ? completeSize <= parseInt(max) : true

  return {
    result,
    message:
      !result &&
      'Maksymalny rozmiar wszystkich plików to :value'.replace(':value', `${max / (1024 * 1024)} MB`)
  }
}

const maxFiles = (
  values,
  max = process.env.REACT_APP_MAX_FILES_AMOUNT
) => {
  const result = values.length ? values.length <= parseInt(max) : true

  return {
    result,
    message:
      !result &&
      'Maksymalna liczba wszystkich plików to :value'.replace(':value', max)
  }
}

export const validators = {
  required,
  email,
  password,
  length,
  maxSize,
  maxSizeMultiple,
  minValue,
  maxValue,
  maxFiles
}
