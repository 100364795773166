import React from 'react'
import { Tooltip } from '@material-ui/core'
import { DragIndicatorOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { sortableHandle } from 'react-sortable-hoc'

const useStyles = makeStyles(theme => ({
  handle: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}))

export const ManualOrderDragHandle = sortableHandle(() => {
  const classes = useStyles()

  return (
    <Tooltip title="Chwyć i przesuń, aby zmienić kolejność">
      <DragIndicatorOutlined className={classes.handle} />
    </Tooltip>
  )
})
