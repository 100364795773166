import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardActions, CardContent, Typography } from '@material-ui/core'
import { ArrowBackIosOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 400,
  },
  card: {
    marginTop: '-10vh',
    width: 412,
  },
  title: {
    marginBottom: 8,
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: theme.palette.primary.main,
    marginLeft: 8,
    marginBottom: 8,
  },
}))

export const NotFound = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Card className={classes.card} raised={true}>
        <CardContent>
          <Typography className={classes.title} variant="h5" component="h2">
            Strona nie istnieje
          </Typography>
          <Typography variant="body2" component="p">
            Wygląda na to, że kliknąłeś niedziałający link lub wprowadziłeś adres, który nie istnieje
          </Typography>
        </CardContent>
        <CardActions>
          <Link className={classes.link} to={'/'}>
            <ArrowBackIosOutlined /> Powrót na stronę główną
          </Link>
        </CardActions>
      </Card>
    </div>
  )
}
