import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Paper } from '@material-ui/core'
import { Loader } from 'components/Loader'
import { UploadDialog } from 'components/form/fields/file/UploadDialog'
import { ThumbCropper } from './ThumbCropper'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { getCrop } from './_helpers/getCrop'
import { getConfig } from './_helpers/getConfig'
import { recalculateCrop } from './_helpers/recalculateCrop'
import { recalculateConfig } from './_helpers/recalculateConfig'
import { useThumbCollectionFetch } from './_helpers/useThumbCollectionFetch'
import photoSchema from '_schema/photo'

export const useStyles = makeStyles(theme => ({
  two_col_layout: {
    display: 'flex',
    justifyContent: 'space-between',
    '&> *:first-child': {
      marginRight: 5,
    },
    '&> *:last-child': {
      marginLeft: 5,
    },
  },
  thumb_image_container: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  thumb_image_container_current: {
    backgroundColor: theme.palette.primary.main,
  },
  thumb_image_container_disabled: {
    backgroundColor: theme.palette.disabled,
    cursor: 'default',
    '&:hover': {
      backgroundColor: theme.palette.disabled,
    },
  },
  thumb_image: {
    width: '100%',
    height: 150,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    marginBottom: '.5em',
  },
  thumb_image_disabled: {
    filter: 'grayscale(100%)',
  },
  thumb_title: {
    marginBottom: '.5em',
    textAlign: 'center',
  },
  thumb_dimensions: {
    color: '#aaa',
    fontSize: '.8em',
    marginTop: 5
  },
  empty_results: {
    margin: 10,
    textAlign: 'center',
  },
}))

export const ThumbCollection = ({
  endpoint,
  pid,
  disabled = false,
  validators = ['maxSize'],
  names = {},
}) => {
  const [state, setState] = useThumbCollectionFetch(endpoint, pid)

  const handleThumbChange = index => () => {
    if (state.isFetching || disabled) {
      return
    }

    setState(state => ({
      ...state,
      currentThumb: index,
    }))
  }

  const handleFullScreen = () => {
    setState(state => ({
      ...state,
      isFullScreen: !state.isFullScreen,
    }))
  }

  const handleUploadDialogToggle = () => {
    setState(state => ({
      ...state,
      isUploadDialogOpen: !state.isUploadDialogOpen,
    }))
  }

  const setCrop = index => newCrop => {
    setState(state => ({
      ...state,
      items: state.items.map((item, i) =>
        i === index ? { ...item, crop: newCrop } : item
      ),
    }))
  }

  const setOriginal = index => dimensions => {
    setState(state => ({
      ...state,
      items: state.items.map((item, i) =>
        i !== index
          ? item
          : {
              ...item,
              original: dimensions,
              crop:
                JSON.stringify(item.original) === JSON.stringify(dimensions)
                  ? item.crop
                  : recalculateCrop(
                      dimensions,
                      getCrop(item.resource),
                      'frame'
                    ),
              config:
                JSON.stringify(item.original) === JSON.stringify(dimensions)
                  ? item.config
                  : recalculateConfig(
                      dimensions,
                      getConfig(item.resource),
                      'frame'
                    ),
            }
      ),
    }))
  }

  const handlePhotoChange = index => resource => {
    const recalculatedCrop = recalculateCrop(
      state.items[index].original,
      {
        x: resource.cropX,
        y: resource.cropY,
        width: resource.cropWidth,
        height: resource.cropHeight,
        aspect: resource.width / resource.height,
      },
      'frame'
    )
    const recalculatedConfig = recalculateConfig(
      state.items[index].original,
      {
        minWidth: resource.width,
        minHeight: resource.height,
        maxWidth: resource.width,
        maxHeight: resource.height,
      },
      'frame'
    )

    setState(state => ({
      ...state,
      items: state.items.map((item, i) =>
        i === index
          ? {
              resource,
              crop: recalculatedCrop,
              config: recalculatedConfig,
            }
          : item
      ),
    }))
  }

  const handleSubmit = (type, index) => () => {
    setState(state => ({
      ...state,
      isFetching: true,
    }))

    let body

    if (type === 'save') {
      const recalculatedCrop = recalculateCrop(
        state.items[index].original,
        state.items[index].crop,
        'natural'
      )

      body = {
        cropX: Math.round(recalculatedCrop.x),
        cropY: Math.round(recalculatedCrop.y),
        cropWidth: Math.round(recalculatedCrop.width),
        cropHeight: Math.round(recalculatedCrop.height),
      }
    } else {
      body = {
        performReset: true,
      }
    }

    fetchDataHandleAuthError(
      state.items[index].resource['@id'],
      'PATCH',
      { body: JSON.stringify(body) },
      response => {
        const recalculatedCrop = recalculateCrop(
          state.items[index].original,
          {
            x: response.cropX,
            y: response.cropY,
            width: response.cropWidth,
            height: response.cropHeight,
            aspect: response.width / response.height,
          },
          'frame'
        )
        const recalculatedConfig = recalculateConfig(
          state.items[index].original,
          {
            minWidth: response.width,
            minHeight: response.height,
            maxWidth: response.width,
            maxHeight: response.height,
          },
          'frame'
        )

        setState(state => ({
          ...state,
          items: state.items.map((item, i) =>
            i === index
              ? {
                  ...item,
                  resource: response,
                  crop: recalculatedCrop,
                  config: recalculatedConfig,
                }
              : item
          ),
          isFetching: false,
        }))

        notification('success', 'Rekord zaktualizowany')
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        setState(state => ({
          ...state,
          isFetching: false,
        }))

        notification('error', error.response.detail)
      }
    )
  }

  const classes = useStyles()

  return (
    <div className={classes.root}>
      {(state.init || state.isFetching) && (
        <Loader align="center" marginBottom={15} />
      )}
      {!state.init &&
        (state.items.length ? (
          <div className={classes.two_col_layout}>
            <Grid
              container
              spacing={2}
              className={classes.thumbs_container}
            >
              {state.items.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={`thumb-${index}`}
                >
                  <Paper
                    onClick={handleThumbChange(index)}
                    className={clsx(
                      classes.thumb_image_container,
                      index === state.currentThumb &&
                        classes.thumb_image_container_current,
                      (state.isFetching || disabled) &&
                        classes.thumb_image_container_disabled
                    )}
                  >
                    <div
                      style={{
                        backgroundImage: `url(${
                          process.env.REACT_APP_API_ENTRYPOINT
                        }${
                          item.resource.url
                        }?v=${
                          item.resource.version
                        })`,
                      }}
                      className={clsx(
                        classes.thumb_image,
                        (state.isFetching || disabled) &&
                          classes.thumb_image_disabled
                      )}
                    />
                  </Paper>
                  <div className={classes.thumb_title}>
                    {names[item.resource.name] || 'brak nazwy kadru'}
                    &nbsp;
                    <div className={classes.thumb_dimensions}>
                      {`${item.resource.width} x ${item.resource.height}`}
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
            <ThumbCropper
              originalPhotoUrl={
                state.items[state.currentThumb].resource.photo?.url
              }
              crop={state.items[state.currentThumb].crop}
              minWidth={state.items[state.currentThumb].config.minWidth}
              minHeight={state.items[state.currentThumb].config.minHeight}
              maxWidth={state.items[state.currentThumb].config.maxWidth}
              maxHeight={state.items[state.currentThumb].config.maxHeight}
              setCrop={setCrop(state.currentThumb)}
              setOriginal={setOriginal(state.currentThumb)}
              handleReset={handleSubmit('reset', state.currentThumb)}
              handleSubmit={handleSubmit('save', state.currentThumb)}
              handleUploadDialogToggle={handleUploadDialogToggle}
              handleFullScreen={handleFullScreen}
              disabled={state.isFetching || disabled}
              isFullScreen={state.isFullScreen}
              key={`${state.isFullScreen ? 'full-screen' : 'default-view'}-${
                state.items[state.currentThumb].resource.uuid
              }`}
            />
            <UploadDialog
              name="photo"
              type="image"
              endpoint={photoSchema.endpoint.single}
              disabled={disabled}
              validators={validators}
              setValue={() => {}}
              setUpdatedAt={() => {}}
              formUrl={state.items[state.currentThumb].resource['@id']}
              formMethod="PATCH"
              formProcessResponse={handlePhotoChange(state.currentThumb)}
              isOpen={state.isUploadDialogOpen}
              handleToggle={handleUploadDialogToggle}
              key={`upload-dialog-${
                state.items[state.currentThumb].resource.uuid
              }`}
            />
          </div>
        ) : (
          <div className={classes.empty_results}>
            Brak kadrów
          </div>
        ))}
    </div>
  )
}

ThumbCollection.propTypes = {
  endpoint: PropTypes.string.isRequired,
  pid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  disabled: PropTypes.bool,
  validators: PropTypes.arrayOf(PropTypes.string),
  names: PropTypes.object.isRequired,
}

