import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Paper as OriginalPaper } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: 'transparent',
  },
  container_with_padding: {
    padding: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    backgroundColor: '#fff',
  },
}))

export const Paper = ({
  children,
  withPadding = true,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <div
      className={clsx(
        classes.container,
        withPadding && classes.container_with_padding
      )}
      {...rest}
    >
      <OriginalPaper
        className={classes.paper}
      >
        {children}
      </OriginalPaper>
    </div>
  )
}

Paper.propTypes = {
  children: PropTypes.node.isRequired,
  withPadding: PropTypes.bool,
}
