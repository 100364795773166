import { matchPath } from 'react-router'

export const getCurrentRoute = (path, routes) => {
  for (let i = 0; i < routes.length; i++) {
    const match = matchPath({
      path: routes[i].path,
      exact: routes[i].exact,
    }, path || '')

    if (match) {
      return {
        match,
        route: { ...routes[i], path },
      }
    }
  }

  return null
}
