import fileSchema from './file'

const schema = {
  endpoint: '/api/configs',
  resource: {
    definition: 'Config-config.read',
    properties: {
      trailsLink: {
        width: 500
      },
      teamRosterFile: {
        type: 'file',
        description: 'Plik pdf ze składem zespołu',
        endpoint: fileSchema.endpoint.single,
        accept: 'application/pdf',
        validate: ['maxSize'],
      },
      fieldVerificationReportsFile: {
        type: 'file',
        description: 'Plik pdf z terenowymi referatami weryfikacyjnymi',
        endpoint: fileSchema.endpoint.single,
        accept: 'application/pdf',
        validate: ['maxSize'],
      },
      hikingTrailblazersFile: {
        type: 'file',
        description: 'Plik pdf z przodownikami turystyki pieszej',
        endpoint: fileSchema.endpoint.single,
        accept: 'application/pdf',
        validate: ['maxSize'],
      },
      hikingBadgesFile: {
        type: 'file',
        description: 'Plik pdf z odznakami turystyki pieszej',
        endpoint: fileSchema.endpoint.single,
        accept: 'application/pdf',
        validate: ['maxSize'],
      },
      hikingCommitteesFile: {
        type: 'file',
        description: 'Plik pdf z komisjami turystyki pieszej',
        endpoint: fileSchema.endpoint.single,
        accept: 'application/pdf',
        validate: ['maxSize'],
      },
      trailsFile: {
        type: 'file',
        description: 'Plik pdf ze szlakami',
        endpoint: fileSchema.endpoint.single,
        accept: 'application/pdf',
        validate: ['maxSize'],
      },
      contactFile: {
        type: 'file',
        description: 'Plik pdf z kontaktem',
        endpoint: fileSchema.endpoint.single,
        accept: 'application/pdf',
        validate: ['maxSize'],
      },
      regulationsFile: {
        type: 'file',
        description: 'Plik pdf z regulaminem',
        endpoint: fileSchema.endpoint.single,
        accept: 'application/pdf',
        validate: ['maxSize'],
      },
      rodoFile: {
        type: 'file',
        description: 'Plik pdf z informacjami o RODO',
        endpoint: fileSchema.endpoint.single,
        accept: 'application/pdf',
        validate: ['maxSize'],
      },
    },
  },
}

export default schema
