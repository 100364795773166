import { authConstants } from '_constants'
import { authService } from '_services'
import { notification } from '_helpers/notification'
import { processError } from '_helpers/processError'

const login = (email, password) => dispatch => {
  dispatch({ type: authConstants.LOGIN_REQUEST, payload: { email } })

  return authService.login(email, password).then(
    token => {
      dispatch({
        type: authConstants.LOGIN_SUCCESS,
        payload: { token },
      })
    },
    error => {
      dispatch({ type: authConstants.LOGIN_FAILURE })
      notification('error', error.toString())
    }
  )
}

const refresh = () => dispatch => {
  dispatch({ type: authConstants.REFRESH_REQUEST })

  return authService.refresh().then(
    token => {
      dispatch({
        type: authConstants.REFRESH_SUCCESS,
        payload: { token },
      })
    },
    error => {
      dispatch({
        type: authConstants.REFRESH_FAILURE,
        payload: { error: processError(error).response },
      })
    }
  )
}

const restore = token => dispatch => {
  dispatch({ type: authConstants.LOGIN_RESTORE_REQUEST })

  return authService.validate(token).then(
    token => {
      dispatch({
        type: authConstants.LOGIN_RESTORE_SUCCESS,
        payload: { token },
      })
    },
    () => {
      dispatch({ type: authConstants.LOGIN_RESTORE_FAILURE })
    }
  )
}

const logout = () => dispatch => {
  dispatch({ type: authConstants.LOGOUT })
}

export const authActions = {
  login,
  refresh,
  restore,
  logout,
}
