import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles, fade } from '@material-ui/core/styles'
import { Loader } from 'components/Loader'

const useStyles = makeStyles({
  root: {
    display: 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: 'center',
    zIndex: 1,
    backgroundColor: fade('#fff', 0.9),
  },
  show: {
    display: 'flex',
  },
  center: {
    justifyContent: 'center',
  },
  left: {
    justifyContent: 'left',
  },
  right: {
    justifyContent: 'right',
  },
})

export const TableLoader = ({ show, align = 'center' }) => {
  const classes = useStyles()

  return (
    <div className={clsx(classes.root, show && classes.show, classes[align] || null)}>
      <Loader align={align} />
    </div>
  )
}

TableLoader.propTypes = {
  show: PropTypes.bool.isRequired,
  align: PropTypes.string,
}
