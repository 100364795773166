import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Tooltip } from '@material-ui/core'
import { RotateLeftOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
  },
  icon: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
    marginBottom: 27,
  },
  disabled: {
    cursor: 'default',
    color: theme.palette.disabled,
    '&:hover': {
      color: theme.palette.disabled,
    },
  },
}))

export const TableReset = ({ disabled = false, handleReset }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Tooltip title="Resetuj ustawienia tabeli">
        <RotateLeftOutlined
          onClick={handleReset}
          className={clsx(classes.icon, disabled && classes.disabled)}
        />
      </Tooltip>
    </div>
  )
}

TableReset.propTypes = {
  disabled: PropTypes.bool,
  handleReset: PropTypes.func.isRequired,
}
