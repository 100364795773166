import { DescriptionOutlined } from '@material-ui/icons'
import home from 'pages/Home/routes'
import news from 'pages/News/routes'
import pages from 'pages/Pages/routes'
import meetings from 'pages/Meetings/routes'
import events from 'pages/Events/routes'
import rallies from 'pages/Rallies/routes'
import badges from 'pages/Badges/routes'
import config from 'pages/Config/routes'
import admins from 'pages/Admins/routes'
import registryEntries from 'pages/RegistryEntries/routes'
import loginEntries from 'pages/LoginEntries/routes'
import profile from 'pages/Profile/routes'

const menu = () =>
  [
    home().index,
    news().index,
    pages().index,
    meetings().index,
    events().index,
    rallies().index,
    badges().index,
    config().edit,
    admins().index,
    {
      title: 'Rejestry',
      icon: DescriptionOutlined,
      items: [registryEntries().index, loginEntries().index],
    },
    profile().index,
  ]

export default menu
