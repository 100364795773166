import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { prop } from '_helpers/prop'

const useStyles = makeStyles(theme => ({
  title: {
    color: '#222'
  },
  custom_tooltip: {
    color: '#111',
    backgroundColor: '#fff',
    border: '1px solid #C4CBD8',
    boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.1)',
    borderRadius: '3px',
    fontSize: '13px',
    marginTop: -27,
    padding: 0,
  },
  wrapper: {
    display: 'flex',
    padding: 10,
    flexDirection: 'column',
    color: '#666'
  },
  icon: {
    color: theme.palette.success.main,
  },
}))

export const Timestamp = ({
  resource,
  createdAtAccessor = 'createdAt',
  updatedAtAccessor = 'updatedAt',
}) => {
  const classes = useStyles()

  return (
    <Tooltip
      classes={{
        tooltip: classes.custom_tooltip,
      }}
      placement="bottom-end"
      title={
        <div className={classes.wrapper}>
          {createdAtAccessor && (
            <div>
              <span className={classes.title}>
                Utworzony:{' '}
              </span>
              {prop(resource, createdAtAccessor)}
            </div>
          )}
          {updatedAtAccessor && (
            <div>
              <span className={classes.title}>
                Ostatnia aktualizacja:{' '}
              </span>
              {prop(resource, updatedAtAccessor)}
            </div>
          )}
        </div>
      }
    >
      <InfoOutlined className={classes.icon} />
    </Tooltip>
  )
}

Timestamp.propTypes = {
  resource: PropTypes.object.isRequired,
  createdAtAccessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  updatedAtAccessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
}
