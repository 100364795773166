import React from 'react'
import { Route } from 'react-router-dom'
import { SpeedOutlined } from '@material-ui/icons'
import { Home } from 'pages/Home/Home'

const routes = () => ({
  index: {
    title: 'Dashboard',
    type: Route,
    render: props => <Home {...props} />,
    path: '/',
    icon: SpeedOutlined,
    exact: true,
  },
})

export default routes
