export const constants = {
  SET_PAGE: 'COLLECTION_SET_PAGE',
  SET_PER_PAGE: 'COLLECTION_SET_PER_PAGE',
  HANDLE_COLUMN_SORTERS: 'COLLECTION_HANDLE_COLUMN_SORTER',
  HANDLE_COLUMN_FILTERS: 'COLLECTION_HANDLE_COLUMN_FILTERS',
  HANDLE_MANUAL_ORDER_SORTER: 'COLLECTION_HANDLE_MANUAL_ORDER_SORTER',
  SET_SORTERS: 'COLLECTION_SET_SORTERS',
  SET_FILTERS: 'COLLECTION_SET_FILTERS',
  RESET: 'COLLECTION_RESET',
  RELOAD: 'COLLECTION_RELOAD',
  FETCH_START: 'COLLECTION_FETCH_START',
  FETCH_SUCCESS: 'COLLECTION_FETCH_SUCCESS',
  FETCH_FAILURE: 'COLLECTION_FETCH_FAILURE',
  MANUAL_ORDER_CHANGE_START: 'COLLECTION_MANUAL_ORDER_CHANGE_START',
  MANUAL_ORDER_CHANGE_SUCCESS: 'COLLECTION_MANUAL_ORDER_CHANGE_SUCCESS',
  MANUAL_ORDER_CHANGE_FAILURE: 'COLLECTION_MANUAL_ORDER_CHANGE_FAILURE',
  UPDATE_ITEM_FIELD: 'COLLECTION_UPDATE_ITEM_FIELD',
}
