import { useState, useMemo } from 'react'

export const useResourceState = (resource = null) => {
  const [state, setState] = useState({
    isFetching: true,
    resource,
    fetchError: false,
  })

  const setters = useMemo(
    () => ({
      isFetching: isFetching =>
        setState(state => ({
          ...state,
          isFetching,
        })),
      resource: resource =>
        setState(state => ({
          ...state,
          isFetching: false,
          resource,
        })),
      fetchError: fetchError =>
        setState(state => ({
          ...state,
          isFetching: false,
          fetchError,
        })),
    }),
    [setState]
  )

  return [state, setters]
}
