import React from 'react'
import { Route } from 'react-router-dom'
import { AssignmentIndOutlined } from '@material-ui/icons'
import { Collection } from './Collection'
import { addRoutesConfig } from '_helpers/addRoutesConfig'

const basePath = '/historia_logowania'

const routes = () =>
  addRoutesConfig(
    {
      index: {
        subtitle: 'Lista',
        type: Route,
        render: props => <Collection {...props} />,
        path: basePath,
        exact: true,
        icon: AssignmentIndOutlined,
      },
    },
    {
      title: 'Historia logowania'
    }
  )

export default routes
