import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from 'components/form'
import { applyProperties } from '_helpers/applyProperties'
import { profileActions } from '_actions'
import schema from '_schema/profile'

export const ProfileForm = ({ resource }) => {
  const definitions = useSelector(state => state.schema?.definitions)
  const properties = applyProperties(
    schema.profile.properties,
    definitions[schema.profile.definition].properties
  )

  const dispatch = useDispatch()
  const handleSuccess = values => {
    dispatch(profileActions.update(values))
  }

  return (
    <Form
      title="Ogólne"
      url={schema.profile.set}
      method="PATCH"
      properties={properties}
      resource={resource}
      handleSuccess={handleSuccess}
      showSubmitAndStayButton={false}
      showCancelButton={false}
    />
  )
}

ProfileForm.propTypes = {
  resource: PropTypes.object.isRequired,
}
