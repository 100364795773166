import React from 'react'
import PropTypes from 'prop-types'
import { Table, TableBody, fade } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { BodySkeleton } from 'components/table'

const useStyles = makeStyles({
  root: {
    marginTop: 10,
  },
  cell: {
    border: 0,
  },
  skeleton: {
    backgroundColor: fade('#888', 0.15),
  },
})

export const MenuSkeleton = ({ rows }) => {
  const classes = useStyles()

  return (
    <Table size="small" className={classes.root}>
      <TableBody>
        <BodySkeleton
          rows={rows}
          columns={1}
          animation={false}
          classes={{
            cell: classes.cell,
            skeleton: classes.skeleton,
          }}
        />
      </TableBody>
    </Table>
  )
}

MenuSkeleton.propTypes = {
  rows: PropTypes.number.isRequired,
}
