import home from 'pages/Home/routes'
import news from 'pages/News/routes'
import pages from 'pages/Pages/routes'
import meetings from 'pages/Meetings/routes'
import events from 'pages/Events/routes'
import rallies from 'pages/Rallies/routes'
import badges from 'pages/Badges/routes'
import admins from 'pages/Admins/routes'
import config from 'pages/Config/routes'
import registryEntries from 'pages/RegistryEntries/routes'
import loginEntries from 'pages/LoginEntries/routes'
import profile from 'pages/Profile/routes'

const routes = () =>
  [
    ...getArray(home()),
    ...getArray(news()),
    ...getArray(pages()),
    ...getArray(meetings()),
    ...getArray(events()),
    ...getArray(rallies()),
    ...getArray(badges()),
    ...getArray(config()),
    ...getArray(admins()),
    ...getArray(loginEntries()),
    ...getArray(registryEntries()),
    ...getArray(profile()),
  ]

const getArray = routes => Object.values(routes)

export default routes
