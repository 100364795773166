import React, { useState } from 'react'
import { Menu, MenuItem, IconButton } from '@material-ui/core'
import { MoreVertOutlined } from '@material-ui/icons'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { EditLink } from 'components/resource/EditLink'
import { DeleteCell } from 'components/table/cells/DeleteCell'
import { StatusCell } from 'components/table/cells/StatusCell'
import { Timestamp } from 'components/resource/Timestamp'
import clsx from 'clsx'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  menu_item: {
    fontSize: 14,
    '& svg': {
      fontSize: 20,
    },
  },
  list_item: {
    minWidth: 32,
  },
  menu_trigger: {
    borderRadius: 4,
    padding: 7,
  },
  menu_trigger_open: {
    border: '1px solid #d3d4d5',
    borderBottomColor: '#fff',
    backgroundColor: '#fff',
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  }
})

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',

    borderTopRightRadius: 0
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

export const operations = (
  accessor = 'title',
  editable = true,
  deletable = true,
  statable = true,
  timestampable = true
) => ({
  header: 'Operacje',
  accessor,
  sortable: false,
  filterable: false,
  Cell: ({
    resource,
    accessor,
    tableState,
    tableStateDispatch,
    editPath = null,
  }) => {
    const classes = useStyles()
    const [menuAnchor, setMenuAnchor] = useState(null)

    const handleMenu = e => {
      setMenuAnchor(e.currentTarget)
    }

    const handleClose = () => {
      setMenuAnchor(null)
    }

    const profile = useSelector(state => state.profile)
    const disabled = profile.uuid === resource.uuid

    return (
      <div className={classes.root}>
        {(editable || deletable) && (
          <>
            <IconButton
              onClick={handleMenu}
              disabled={disabled}
              className={clsx(
                classes.menu_trigger,
                menuAnchor && classes.menu_trigger_open
              )}
            >
              <MoreVertOutlined color="inherit" />
            </IconButton>
            <StyledMenu
              anchorEl={menuAnchor}
              keepMounted
              open={!!menuAnchor}
              onClose={handleClose}
            >
              {editable && (
                <MenuItem className={classes.menu_item}>
                  <EditLink
                    resource={resource}
                    accessor={accessor}
                    disabled={disabled}
                    editPath={editPath}
                  />
                </MenuItem>
              )}
              {deletable && (
                <MenuItem className={classes.menu_item}>
                  <DeleteCell
                    resource={resource}
                    accessor={accessor}
                    disabled={disabled}
                    tableState={tableState}
                    tableStateDispatch={tableStateDispatch}
                  />
                </MenuItem>
              )}
            </StyledMenu>
          </>
        )}
        {statable && (
          <StatusCell
            resource={resource}
            disabled={disabled}
            tableStateDispatch={tableStateDispatch}
          />
        )}
        {timestampable && <Timestamp resource={resource} />}
      </div>
    )
  },
})
