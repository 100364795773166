import { fetchData } from '_helpers/fetchData'

const SCHEMA_PATH = '/api/docs'

const get = () =>
  fetchData(`${SCHEMA_PATH}`, 'GET', {
    accept: 'application/json',
  }).then(
    response => ({ response }),
    error => ({ error })
  )

export const schemaService = {
  get,
}
