import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { TextField, FormHelperText } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { validate } from '_helpers/validate'

const useStyles = makeStyles({
  slug: {
    marginTop: 5,
  },
  link: {
    color: '#555',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
})

export const SlugType = ({
  name,
  routes,
  label,
  hint = null,
  initialValue,
  value,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
  fullWidth = false,
}) => {
  const handleChange = e => {
    const value = e.target.value
    setValue(name, value)
    validateField(value)
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  useEffect(() => {
    validateField(initialValue)
  }, [validateField, initialValue])

  const classes = useStyles()

  return (
    <>
      <TextField
        name={name}
        type="text"
        label={
          (label.text || label) +
          (validators && validators.includes('required') ? ' *' : '')
        }
        value={value || ''}
        disabled={disabled}
        onChange={handleChange}
        error={renderError && !!error}
        helperText={renderError && error ? error : hint}
        fullWidth={fullWidth}
        variant="outlined"
      />
      {value && (
        <FormHelperText className={classes.slug}>
          Adres url:{' '}
          <a
            href={`${process.env.REACT_APP_API_ENTRYPOINT}${routes.replace(':slug', value)}`}
            className={classes.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {`${process.env.REACT_APP_API_ENTRYPOINT}${routes.replace(':slug', value)}`}
          </a>
        </FormHelperText>
      )}
    </>
  )
}

SlugType.propTypes = {
  name: PropTypes.string.isRequired,
  routes: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pl: PropTypes.string.isRequired,
      en: PropTypes.string.isRequired,
    })
  ]).isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({ text: PropTypes.string.isRequired }),
  ]).isRequired,
  hint: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
}
