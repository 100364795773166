import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Loader } from './Loader'

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
})

export const RestoreLoader = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Loader size="5.0rem" disableShrink={true} />
    </div>
  )
}

