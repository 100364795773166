import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import { Button, FormHelperText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Loader } from 'components/Loader'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { validate } from '_helpers/validate'

const dropzoneStyles = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 30,
  marginBottom: 15,
  color: '#bdbdbd',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eee',
  borderStyle: 'dashed',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
}

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center'
  },
  chosen_files_wrapper: {
    textAlign: 'center'
  },
  chosen_files: {
    color: theme.palette.text.secondary,
    marginTop: 10,
    marginBottom: 20,
  },
  error: {
    textAlign: 'center',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}))

export const MassUpload = ({
  type = 'file',
  hint = null,
  endpoint,
  disabled = false,
  validators = ['maxSize', 'maxFiles'],
  accept = null,
  onSuccess = null
}) => {
  const [state, setState] = useState({
    files: [],
    error: false,
    isFetching: false,
  })

  const handleSend = () => {
    if (!state.files.length) {
      return
    }

    const formData = new FormData()
    state.files.map(file => formData.append('file[]', file))

    setState(state => ({
      ...state,
      isFetching: true,
    }))

    fetchDataHandleAuthError(
      endpoint,
      'POST',
      { body: formData },
      response => {
        uploadSuccess()

        onSuccess && onSuccess(response)
      },
      error => {
        uploadFailure(error)
      },
      { 'Content-Type': 'multipart/form-data' }
    )
  }

  const uploadSuccess = () => {
    setState({
      files: [],
      error: false,
      isFetching: false,
    })

    notification('success', 'Pliki wgrane')
  }

  const uploadFailure = error => {
    if (error.response.title === 'AbortError') {
      return
    }

    setState(state => ({
      ...state,
      isFetching: false,
    }))

    notification('error', error.response.detail, error.response.title)
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setState(state => ({
          ...state,
          error: false,
        }))

        return
      }

      const valid = validate(validators, value)

      setState(state => ({
        ...state,
        error: !valid.result && valid.message,
      }))
    },
    [validators]
  )

  const onDrop = useCallback(
    files => {
      setState(state => ({
        ...state,
        files: [...files],
      }))

      for (let i = 0; i < files.length; i++) {
        validateField(files[i])
      }

      validateField([...files])
    },
    [setState, validateField]
  )

  const { getRootProps, getInputProps } = useDropzone({
    accept:
      accept || (type === 'image' ? 'image/jpg,image/jpeg,image/png' : '*'),
    disabled: state.isFetching || disabled,
    onDrop,
  })

  const classes = useStyles()

  return (
    <div>
      {state.isFetching && <Loader align="center" marginBottom={15} />}
      <div {...getRootProps({ style: dropzoneStyles })}>
        <input {...getInputProps()} />
        Przeciągnij i upuść lub kliknij, żeby wybrać
      </div>
      <div className={classes.chosen_files_wrapper}>
        Wybrane pliki:
        <div className={classes.chosen_files}>
          {state.files.length ?
            state.files.map((file, index) => (
              <div key={`file-${index}`}>{file.name}</div>
            )) : 'brak'}
        </div>
        <FormHelperText
          error={!!state.error}
          disabled={disabled}
          className={classes.error}
        >
          {state.error || hint}
        </FormHelperText>
      </div>
      <div className={classes.buttons}>
        <Button
          color="primary"
          disabled={!state.files.length || !!state.error || state.isFetching || disabled}
          onClick={handleSend}
          variant="contained"
        >
          Wyślij
        </Button>
      </div>
    </div>
  )
}

MassUpload.propTypes = {
  type: PropTypes.oneOf(['file', 'image']),
  hint: PropTypes.string,
  endpoint: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  validators: PropTypes.arrayOf(PropTypes.string),
  accept: PropTypes.string,
  onSuccess: PropTypes.func,
}
