import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { NavLink } from 'react-router-dom'
import { ListItem, ListItemIcon } from '@material-ui/core'

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
    color: '#444',
    '& *': {
      color: '#444',
    },
  },
  active: {
    '& > div': {
      backgroundColor: '#ccc',
    }
  },
  list_item: {
    paddingTop: 12,
    paddingBottom: 12,
    '&:hover': {
      backgroundColor: '#ccc',
    },
  },
  list_item_icon: {
    minWidth: 44,
  },
  icon: {
    fontSize: 22,
  },
})

export const MenuItem = ({ item, exact = false, classes = {} }) => {
  const defaultClasses = useStyles()

  const IconComponent = item.icon

  return (
    <NavLink
      to={item.path}
      className={defaultClasses.link}
      activeClassName={defaultClasses.active}
      exact={exact || (item.path.length > 1 ? false : !!item.exact)}
    >
      <ListItem
        button
        className={clsx(defaultClasses.list_item, classes.list_item)}
      >
        <ListItemIcon
          className={clsx(defaultClasses.list_item_icon, classes.list_item_icon)}
        >
          <IconComponent
            color="inherit"
            className={clsx(defaultClasses.icon, classes.icon)}
          />
        </ListItemIcon>
        <div>{item.title}</div>
      </ListItem>
    </NavLink>
  )
}

MenuItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    exact: PropTypes.bool,
    icon: PropTypes.elementType.isRequired,
  }).isRequired,
  exact: PropTypes.bool,
  classes: PropTypes.shape({
    list_item: PropTypes.string,
    list_item_icon: PropTypes.string,
    icon: PropTypes.string
  })
}
