import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { BlankForm } from './BlankForm'
import { Paper } from 'components/Paper'
import { Loader } from 'components/Loader'
import { useResourceState } from '_helpers/useResourceState'
import { useResourceFetch } from '_helpers/useResourceFetch'
import { commonConstants } from '_constants'
import { NotFound } from 'pages/NotFound'

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(4),
    backgroundColor: 'transparent',
  }
}))

export const Form = ({
  iri = null,
  resource: fetchedResource = null,
  collectionPath = null,
  editPath = null,
  storeCollectionId = null,
  isCurrentResource = true,
  wrapWithPaper = true,
  children = null,
  paddingBottom = 60,
  ...rest
}) => {
  const [state, setState] = useResourceState(fetchedResource)

  const dispatch = useDispatch()
  const dispatchOnFetch = useCallback(
    resource =>
      isCurrentResource &&
      dispatch({
        type: commonConstants.SET_CURRENT_RESOURCE,
        payload: { resource },
      }),
    [isCurrentResource, dispatch]
  )

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    iri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    false,
    dispatchOnFetch
  )

  const calculatedCollectionPath = useMemo(
    () =>
      collectionPath
        ? typeof collectionPath === 'function'
          ? resource
            ? collectionPath(resource)
            : null
          : collectionPath
        : null,
    [collectionPath, resource]
  )

  const calculatedStoreCollectionId = useMemo(
    () =>
      storeCollectionId
        ? typeof storeCollectionId === 'function'
          ? resource
            ? storeCollectionId(resource)
            : null
          : storeCollectionId
        : null,
    [storeCollectionId, resource]
  )

  const WrapComponent = wrapWithPaper ? Paper : 'div'

  const classes = useStyles()

  return isFetching ? (
    <WrapComponent>
      <Loader />
    </WrapComponent>
  ) : fetchError ? (
    <NotFound />
  ) : (
    <div className={classes.container} style={{ paddingBottom }}>
      <Wrapper wrapWithPaper={false}>
        <BlankForm
          title={iri ? 'Edycja' : 'Nowy'}
          resource={resource}
          iri={iri}
          collectionPath={calculatedCollectionPath}
          editPath={editPath}
          storeCollectionId={calculatedStoreCollectionId}
          isCurrentResource={isCurrentResource}
          {...rest}
        />
        {typeof children === 'function' ? children(resource) : children}
      </Wrapper>
    </div>
  )
}

const Wrapper = ({ children, wrapWithPaper }) =>
  wrapWithPaper ? (
    <Paper withPadding={false}>{children}</Paper>
  ) : (
    <div>{children}</div>
  )

Form.propTypes = {
  iri: PropTypes.string,
  resource: PropTypes.object,
  collectionPath: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  editPath: PropTypes.string,
  storeCollectionId: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  isCurrentResource: PropTypes.bool,
  wrapWithPaper: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  paddingBottom: PropTypes.number
}
