import photoSchema from './photo'
import fileSchema from './file'

const schema = {
  endpoint: '/api/meetings',
  resource: {
    definition: 'Meeting-meeting.read',
    properties: {
      title: {
        width: 600,
        validate: ['required']
      },
      year: {
        validate: ['required']
      },
      file: {
        type: 'file',
        description: 'Plik pdf',
        endpoint: fileSchema.endpoint.single,
        accept: 'application/pdf',
        validate: ['maxSize'],
      },
      slug: {
        type: 'slug',
        routes: '/narada/:slug',
        hint: 'Automatycznie generowany z tytułu',
        width: 300
      },
      stat: {}
    },
  },
  subresources: {
    photo: {
      endpoint: '/api/meeting_photos',
      definition: 'MeetingPhoto-meetingPhoto.read',
      properties: {
        title: {
          width: 600,
        },
        photo: {
          type: 'image',
          endpoint: photoSchema.endpoint.single,
          thumbs: {
            endpoint: '/api/meeting_photo_thumbs',
            names: {
              main: 'Kadr główny'
            }
          },
          validate: ['maxSize'],
        },
        stat: {},
      },
    }
  }
}

export default schema
