import { combineReducers } from 'redux'
import { authReducer } from './auth.reducer'
import { commonReducer } from './common.reducer'
import { collectionReducer } from './collection.reducer'
import { profileReducer } from './profile.reducer'
import { schemaReducer } from './schema.reducer'

const reducers = combineReducers({
  auth: authReducer,
  common: commonReducer,
  collection: collectionReducer,
  profile: profileReducer,
  schema: schemaReducer,
})

export default reducers
