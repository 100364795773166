import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Paper } from 'components/Paper'
import { SchemableCollection } from 'components/SchemableCollection'
import { CollectionTable } from 'components/table'
import { ResultCell } from './table/cells/ResultCell'
import schema from '_schema/loginEntry'
import routes from './routes'

const useStyles = makeStyles({
  title: {
    fontSize: 22,
  }
})

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        accessor: 'username',
        filterable: true,
        sortable: true,
        width: '25%',
      },
      {
        accessor: 'createdAt',
        filterable: true,
        sortable: true,
        width: '25%',
        header: 'Data logowania',
      },
      {
        accessor: 'ip',
        filterable: true,
        sortable: true,
        width: '20%',
      },
      {
        accessor: 'result',
        filterable: true,
        sortable: true,
        width: '23%',
        Cell: ResultCell,
      },
    ],
    []
  )

  const classes = useStyles()

  return (
    <Paper>
      <div className={classes.title}>
        {routes().index.title}
      </div>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint}.get`}
        endpoint={schema.endpoint}
        columns={columns}
        defaultSorters={{ createdAt: 'desc' }}
      />
    </Paper>
  )
}
