import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { CreateNewFolderOutlined } from '@material-ui/icons'
import { Button, Dialog, DialogContent } from '@material-ui/core'
import { DialogTitleWithClose } from 'components/DialogTitleWithClose'
import { MultipleUpload } from './MultipleUpload'

const useStyles = makeStyles({
  content: {
    textAlign: 'left',
  },
})

export const MultipleUploadDialog = ({
  title,
  parentIri,
  fileEndpoint,
  itemEndpoint,
  itemProperties,
  fileProperty = 'photo',
  fileType = 'image',
  disabled = false,
  handleMassUploadSuccess = null,
}) => {
  const [isMassUploadDialogOpen, setIsMassUploadDialogOpen] = useState(false)

  const handleMassUploadDialogToggle = useCallback(() => {
    setIsMassUploadDialogOpen(state => !state)
  }, [])

  const handleItemMassCancel = e => {
    e.preventDefault()
    handleMassUploadDialogToggle()
  }

  const handleResourceSubmit = () => {
    handleMassUploadDialogToggle()

    handleMassUploadSuccess && handleMassUploadSuccess()
  }

  const classes = useStyles()

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<CreateNewFolderOutlined />}
        onClick={handleMassUploadDialogToggle}
      >
        {title}
      </Button>
      <Dialog
        maxWidth="md"
        onClose={handleMassUploadDialogToggle}
        open={isMassUploadDialogOpen}
      >
        <DialogTitleWithClose onClose={handleMassUploadDialogToggle}>
          {title}
        </DialogTitleWithClose>
        <DialogContent className={classes.content}>
          <MultipleUpload
            parentIri={parentIri}
            fileEndpoint={fileEndpoint}
            itemEndpoint={itemEndpoint}
            itemProperties={itemProperties}
            fileProperty={fileProperty}
            fileType={fileType}
            onResourceSubmit={handleResourceSubmit}
            onResourceCancel={handleItemMassCancel}
            disabled={disabled}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

MultipleUploadDialog.propTypes = {
  title: PropTypes.string.isRequired,
  parentIri: PropTypes.string.isRequired,
  fileEndpoint: PropTypes.string.isRequired,
  itemEndpoint: PropTypes.string.isRequired,
  itemProperties: PropTypes.object.isRequired,
  fileProperty: PropTypes.string,
  fileType: PropTypes.oneOf(['file', 'image']),
  disabled: PropTypes.bool,
  handleMassUploadSuccess: PropTypes.func
}
