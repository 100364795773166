import _ from 'lodash'
import { constants } from './constants'

export const reducer = (state, action) => {
  switch (action.type) {
    case constants.SET_VALUE:
      return {
        ...state,
        values: {
          ...state.values,
          [action.payload.name]: action.payload.value,
        },
        renderError: {
          ...state.renderError,
          [action.payload.name]:
            action.payload.setRenderError ||
            state.renderError[action.payload.name],
        },
      }
    case constants.SET_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload.name]: action.payload.error,
        },
        isInvalid:
          Object.keys(state.errors)
            .filter(key => key !== action.payload.name)
            .reduce(
              (isInvalid, key) => !!state.errors[key] || isInvalid,
              false
            ) || !!action.payload.error,
      }
    case constants.RENDER_ERROR:
      return {
        ...state,
        renderError: Object.assign(
          {},
          ...Object.keys(state.renderError).map(name => ({ [name]: true }))
        ),
      }
    case constants.SUBMIT:
      return {
        ...state,
        isSubmitted: true,
      }
    case constants.PROCESS:
      return {
        ...state,
        isProcessing: action.payload,
      }
    case constants.SUCCESS:
      return {
        ...state,
        isProcessing: false,
        values:
          action.payload.method === 'PUT'
            ? _.merge(state.values, action.payload.resource)
            : state.values,
      }
    case constants.FAILURE:
      return {
        ...state,
        isProcessing: false,
        errors: {
          ...state.errors,
          ...action.payload.errors,
        },
      }
    default:
      return state
  }
}
