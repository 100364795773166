import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  link: {
    width: '100%',
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
    },
  },
  disabled: {
    color: theme.palette.disabled,
  },
}))

export const EditLink = ({
  resource,
  disabled = false,
  editPath = null
}) => {
  const classes = useStyles()
  return (
    <>
      {disabled ? (
        <span className={classes.disabled}>
          Edytuj
        </span>
      ) : (
        <Link
          to={editPath
            ? editPath.replace(':id', resource.uuid)
            : resource['@id']}
          className={classes.link}
        >
          Edytuj
        </Link>
      )}
    </>
  )
}

EditLink.propTypes = {
  resource: PropTypes.shape({
    uuid: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
  editPath: PropTypes.string,
}
