import React from 'react'
import PropTypes from 'prop-types'
import { TableRow, TableCell } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  skeleton: {
    zIndex: 1,
  },
})

export const BodySkeleton = ({
  rows,
  columns,
  animation = 'wave',
}) => {
  const classes = useStyles()

  return [...Array(rows)].map((row, i) => (
    <TableRow key={`skeleton-${i}`}>
      {[...Array(columns)].map((cell, j) => (
        <TableCell key={`cell-${j}`}>
          <Skeleton
            animation={animation}
            className={classes.skeleton}
          />
        </TableCell>
      ))}
    </TableRow>
  ))
}

BodySkeleton.propTypes = {
  rows: PropTypes.number.isRequired,
  columns: PropTypes.number.isRequired,
  animation: PropTypes.oneOf(['pulse', 'wave', false]),
}
