import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import {
  AppBar,
  Toolbar,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'
import { MenuOutlined, MenuOpen } from '@material-ui/icons'
import { UserMenu } from 'components/menu'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { commonConstants } from '_constants'

export const useStyles = makeStyles(theme => ({
  app_bar: {
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  app_bar_sidebar_opened: {
    marginLeft: 240,
    width: `calc(100% - 240px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    paddingLeft: 16,
    paddingRight: 12,
    backgroundColor: '#fff',
  },
  sidebar_close: {
    marginRight: 12,
  },
  breadcrumbs: {
    display: 'flex',
    marginRight: 'auto',
  },
  skeleton: {
    display: 'flex',
    marginRight: 'auto',
    width: 220,
  },
}))

export const TopBar = ({ breadcrumbs, isSidebarOpen, isMobile, loading }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleSidebarToggle = () => {
    dispatch({ type: commonConstants.SIDEBAR_TOGGLE })
  }

  return (
    <AppBar
      position="absolute"
      className={clsx(
        classes.app_bar,
        isSidebarOpen && classes.app_bar_sidebar_opened
      )}
    >
      <Toolbar className={classes.toolbar}>
        {!isMobile && (
          <Tooltip title="Zmniejsz/powiększ menu">
            <IconButton
              edge="start"
              onClick={handleSidebarToggle}
              className={classes.sidebar_close}
            >
              {isSidebarOpen ? <MenuOpen /> : <MenuOutlined />}
            </IconButton>
          </Tooltip>
        )}
        {loading ? (
          <Skeleton animation="wave" className={classes.skeleton} />
        ) : (
          <Breadcrumbs
            breadcrumbs={breadcrumbs}
            className={classes.breadcrumbs}
          />
        )}
        <UserMenu />
      </Toolbar>
    </AppBar>
  )
}

TopBar.propTypes = {
  breadcrumbs: PropTypes.shape({
    paths: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        path: PropTypes.string,
      })
    ),
    custom: PropTypes.bool,
  }),
  isSidebarOpen: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
}
