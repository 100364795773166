const schema = {
  endpoint: '/api/admins',
  resource: {
    definition: 'Admin.AdminInputDTO-admin.write',
    properties: {
      fullName: {
        validate: ['required', 'length(3)'],
      },
      email: {
        validate: ['required', 'email'],
      },
      password: {
        type: 'passwordRepeat',
        description: {
          origin: 'Nowe hasło',
          repeat: 'Powtórz nowe hasło'
        },
        hint: {
          origin: 'Podaj nowe hasło',
          repeat: 'Powtórz nowe hasło',
        },
        validate: ['required', 'password'],
      },
      stat: {
        description: 'Administrator włączony'
      }
    },
  },
}

export default schema
