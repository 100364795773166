import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTheme, useMediaQuery } from '@material-ui/core'
import { TopBar, SideBar } from '.'
import { generateBreadcrumbs } from '_helpers/generateBreadcrumbs'

export const Bars = ({ location, menu, routes, loading = false }) => {
  const isSidebarOpen = useSelector(state => state.common.isSidebarOpen)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const { pathname } = location

  const breadcrumbs = useMemo(() => {
    return { paths: generateBreadcrumbs(pathname, routes) }
  }, [pathname, routes])

  return (
    <>
      <TopBar
        breadcrumbs={breadcrumbs}
        isMobile={isMobile}
        isSidebarOpen={!isMobile && isSidebarOpen}
        loading={loading}
      />
      <SideBar
        menu={menu}
        isSidebarOpen={!isMobile && isSidebarOpen}
        loading={loading}
      />
    </>
  )
}

const itemShape = PropTypes.shape({
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  icon: PropTypes.elementType.isRequired,
})

Bars.propTypes = {
  location: PropTypes.object.isRequired,
  menu: PropTypes.arrayOf(
    PropTypes.oneOfType([
      itemShape,
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        icon: PropTypes.elementType.isRequired,
        items: PropTypes.arrayOf(itemShape).isRequired,
      }),
    ])
  ).isRequired,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      path: PropTypes.string,
      from: PropTypes.string,
      exact: PropTypes.bool,
    })
  ).isRequired,
  loading: PropTypes.bool,
}
