import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { useHistory } from 'react-router-dom'
import { prop } from '_helpers/prop'

const useStyles = makeStyles({
  root: {
    position: 'relative',
    width: '100%',
  },
  img: {
    position: 'relative',
    width: '90%',
  },
})

export const PhotoCell = ({
  resource,
  accessor,
  isRowLinkable = false,
  editPath = null,
}) => {
  const history = useHistory()

  const onClick = () => {
    if (isRowLinkable) {
      editPath &&
        history.push(
          editPath.replace(':id', resource.uuid)
        )
    }
  }

  const classes = useStyles()

  return (
    <div onClick={onClick} className={classes.root}>
      {prop(resource, accessor) && (
        <img
          src={`${process.env.REACT_APP_API_ENTRYPOINT}/${
            prop(resource, accessor).url
          }`}
          className={classes.img}
          alt="preview"
        />
      )}
    </div>
  )
}

PhotoCell.propTypes = {
  resource: PropTypes.shape({
    uuid: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  accessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  isRowLinkable: PropTypes.bool,
  editPath: PropTypes.string,
}
