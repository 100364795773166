import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import { AddCircleOutlined } from '@material-ui/icons'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { constants } from 'components/embedded/_state'

export const Add = ({ endpoint, parent, dispatch, disabled = false }) => {
  const handleSubmit = () => {
    dispatch({ type: constants.FETCH_START })

    fetchDataHandleAuthError(
      endpoint,
      'POST',
      {
        body: JSON.stringify({
          parent,
        }),
      },
      response => {
        dispatch({ type: constants.ADD_ITEM, payload: { value: response } })

        notification('success', 'Rekord utworzony')
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        dispatch({ type: constants.FETCH_FAILURE })
        notification('error', error.response.detail)
      }
    )
  }

  return (
    <Button
      size="small"
      variant="contained"
      color="primary"
      onClick={handleSubmit}
      disabled={disabled}
      startIcon={<AddCircleOutlined />}
    >
      Dodaj
    </Button>
  )
}

Add.propTypes = {
  endpoint: PropTypes.string.isRequired,
  parent: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}
