import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  FullscreenOutlined,
  FullscreenExitOutlined,
  GetAppOutlined,
} from '@material-ui/icons'
import { Button } from '@material-ui/core'
import { makeStyles, fade } from '@material-ui/core/styles'
import { Crop } from './Crop'

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
  },
  full_screen: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: theme.zIndex.drawer + 2,
    background: fade('#eef0f4', 0.8),
    padding: '30px 0',
    overflowY: 'scroll',
  },
  container: {
    display: 'inline-block',
  },
  additional_buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  full_screen_button: {
    color: theme.palette.info.main,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  submit_buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 15,
    '&> *': {
      marginLeft: 10,
      marginRight: 10
    },
    '&> *:last-child': {
      marginRight: 0
    }
  },
  reset_button: {
    color: '#fff',
    background: theme.palette.error.main,
    '&:hover': {
      background: fade(theme.palette.error.main, 0.9),
    },
    '&:active': {
      background: theme.palette.error.main,
    },
  },
  crop_full_screen: {
    '& img': {
      maxHeight: '85vh',
    },
  },
}))

export const ThumbCropper = ({
  originalPhotoUrl,
  crop,
  minWidth,
  minHeight,
  maxWidth,
  maxHeight,
  setCrop,
  setOriginal = null,
  handleFullScreen,
  handleReset,
  handleSubmit,
  handleUploadDialogToggle = null,
  disabled = false,
  isFullScreen = false,
}) => {
  const classes = useStyles()

  return (
    <div className={clsx(classes.root, isFullScreen && classes.full_screen)}>
      <div className={classes.container}>
        <Crop
          src={`${process.env.REACT_APP_API_ENTRYPOINT}/${originalPhotoUrl}`}
          crop={crop}
          minWidth={minWidth}
          minHeight={minHeight}
          maxWidth={maxWidth}
          maxHeight={maxHeight}
          setCrop={setCrop}
          setOriginal={setOriginal}
          disabled={disabled}
          classes={{
            crop: clsx(classes.crop, isFullScreen && classes.crop_full_screen),
          }}
        />
        <div className={classes.additional_buttons}>
          <div
            className={classes.full_screen_button}
            onClick={handleFullScreen}
          >
            {isFullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
            {isFullScreen ? 'Widok domyślny' : 'Pełny ekran'}
          </div>
        </div>
        <div className={classes.submit_buttons}>
          <Button
            variant="contained"
            size="small"
            className={classes.reset_button}
            disabled={disabled}
            onClick={handleReset}
          >
            Resetuj
          </Button>
          {handleUploadDialogToggle && (
            <Button
              variant="contained"
              color="default"
              size="small"
              disabled={disabled}
              onClick={handleUploadDialogToggle}
              endIcon={<GetAppOutlined />}
            >
              Zmień plik
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={disabled}
            onClick={handleSubmit}
          >
            Zapisz
          </Button>
        </div>
      </div>
    </div>
  )
}

ThumbCropper.propTypes = {
  originalPhotoUrl: PropTypes.string.isRequired,
  crop: PropTypes.object.isRequired,
  minWidth: PropTypes.number.isRequired,
  minHeight: PropTypes.number.isRequired,
  maxWidth: PropTypes.number.isRequired,
  maxHeight: PropTypes.number.isRequired,
  setCrop: PropTypes.func.isRequired,
  setOriginal: PropTypes.func,
  handleFullScreen: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleUploadDialogToggle: PropTypes.func,
  disabled: PropTypes.bool,
  isFullScreen: PropTypes.bool,
}
