import React from 'react'
import { Form } from 'components/form'
import schema from '_schema/profile'

export const ChangePasswordForm = () => (
  <Form
    title="Zmiana hasła"
    url={schema.changePassword.set}
    method="PATCH"
    properties={schema.changePassword.properties}
    showSubmitAndStayButton={false}
    showCancelButton={false}
  />
)
