import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Link as MUILink,
  Breadcrumbs as MUIBreadcrumbs,
  Typography,
  Tooltip,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { modifyBreadcrumbs } from '_helpers/modifyBreadcrumbs'
import { commonConstants } from '_constants'
import homeRoutes from 'pages/Home/routes'

const useStyles = makeStyles({
  speed: {
    verticalAlign: 'middle',
    marginBottom: '.1em',
  },
})

export const Breadcrumbs = ({ breadcrumbs, ...rest }) => {
  const classes = useStyles()

  const homeRoute = homeRoutes().index
  const HomeIcon = homeRoute.icon

  const [paths, setPaths] = useState({
    raw: breadcrumbs.paths,
    final: breadcrumbs.paths,
  })

  const dispatch = useDispatch()
  const { currentResource, currentParentResource } = useSelector(
    state => state.common
  )

  useEffect(() => {
    setPaths({
      raw: breadcrumbs.paths,
      final: breadcrumbs.paths,
    })
  }, [breadcrumbs.paths])

  useEffect(() => {
    if (!currentParentResource && !currentResource) {
      return
    }

    setPaths(state => ({
      ...state,
      final: modifyBreadcrumbs(
        currentResource,
        currentResource?.parent || currentParentResource,
        JSON.parse(JSON.stringify(state.raw))
      ),
    }))

    dispatch({ type: commonConstants.RESET_CURRENT_RESOURCES })
  }, [currentResource, currentParentResource, dispatch])

  return (
    <MUIBreadcrumbs aria-label="breadcrumbs" {...rest}>
      {paths.final?.length && paths.final[0].path !== homeRoute.path && (
        <MUILink component={Link} to={homeRoute.path}>
          <Tooltip title={homeRoute.title}>
            <HomeIcon className={classes.speed} />
          </Tooltip>
        </MUILink>
      )}
      {(paths.final || [])
        .filter(item => item.title)
        .map((item, index, array) =>
          array.length === index + 1 ? (
            <Typography key={index}>{item.title}</Typography>
          ) : (
            <MUILink
              component={Link}
              to={item.path}
              key={`breadcrumb-${index}`}
            >
              {item.title}
            </MUILink>
          )
        )}
    </MUIBreadcrumbs>
  )
}

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.shape({
    paths: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        path: PropTypes.string,
      })
    ),
  }),
}
