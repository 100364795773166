import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { DialogTitleWithClose } from 'components/DialogTitleWithClose'
import { ThumbCollection } from 'components/thumb'

const useStyles = makeStyles({
  empty_results: {
    margin: 10,
    textAlign: 'center',
  },
  dialog_content: {
    marginBottom: 15,
  },
  paper: {
    maxHeight: '90vh',
  },
  paper_full_width: {
    width: 'auto',
  },
})

export const ThumbsDialog = ({
  endpoint,
  pid = false,
  originalPhotoUrl = false,
  disabled = false,
  isOpen,
  handleToggle,
  names = {},
}) => {
  const classes = useStyles()

  return (
    <Dialog
      open={isOpen}
      onClose={handleToggle}
      fullWidth={true}
      maxWidth="lg"
      classes={{
        paper: classes.paper,
        paperFullWidth: classes.paper_full_width,
      }}
    >
      <DialogTitleWithClose onClose={handleToggle}>
        Kadry
      </DialogTitleWithClose>
      <DialogContent
        className={classes.dialog_content}
      >
        {pid && originalPhotoUrl ? (
          <ThumbCollection
            endpoint={endpoint}
            pid={pid}
            disabled={disabled}
            names={names}
          />
        ) : (
          <div
            className={classes.empty_results}
          >
            Brak kadrów
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}

ThumbsDialog.propTypes = {
  endpoint: PropTypes.string.isRequired,
  pid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  originalPhotoUrl: PropTypes.string,
  disabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  handleToggle: PropTypes.func.isRequired,
  names: PropTypes.object.isRequired,
}
