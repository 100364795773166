import React from 'react'
import PropTypes from 'prop-types'

export const Logo = ({ type = 'normal' }) => {
  return (
    <img src={`/img/logo${type === 'small' ? '_small' : ''}.png`} alt="logo" />
  )
}

Logo.propTypes = {
  type: PropTypes.oneOf(['normal', 'small'])
}
