import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchDataHandleAuthError } from '_helpers/fetchDataHandleAuthError'
import { notification } from '_helpers/notification'
import { commonConstants } from '_constants'

export const useResourceFetch = (
  iri,
  resource,
  setIsFetching,
  setResource,
  setFetchError,
  isByPidFetch = false,
  dispatchOnFetch = null
) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (!iri || resource) {
      setIsFetching(false)
      return
    }

    const controller = new AbortController()
    const { signal } = controller

    fetchDataHandleAuthError(
      iri,
      'GET',
      { signal },
      response => {
        setResource(response)
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail)
        setFetchError(true)
      }
    )

    return () => controller.abort()
  }, [
    iri,
    resource,
    setIsFetching,
    setResource,
    setFetchError,
  ])

  useEffect(() => {
    if (!resource) {
      return
    }

    dispatchOnFetch
      ? dispatchOnFetch(resource)
      : dispatch({
          type: isByPidFetch
            ? commonConstants.SET_CURRENT_PARENT_RESOURCE
            : commonConstants.SET_CURRENT_RESOURCE,
          payload: { resource },
        })
  }, [dispatch, dispatchOnFetch, resource, isByPidFetch])

  return
}
